import api from '../api';
interface Data {
  bank_name: string;
}
export const getBankList = async () => {
  let bankList = [];
  try {
    const result = await api.bankList.getBankList();
    if (result) {
      let bankListTemp = result.data;
      bankListTemp.map((data: Data) => {
        bankList.push({
          label: data?.bank_name,
          value: data?.bank_name,
        });
      });
    }
  } catch (err) {
    console.log('err', err);
  }
  return bankList;
};
