import * as yup from 'yup';

import { customDate } from '../../utils';

export const schema = yup.object().shape({
  ktp: yup
    .string()
    .required('Nomor ktp harus diisi')
    .length(16, 'Nomor ktp harus terdiri dari 16 angka')
    .matches(/^[0-9]*$/, 'Nomor ktp harus berisi angka'),
  dob: customDate()
    .format('DD-MM-YYYY')
    .required('Tanggal Kelahiran harus diisi')
    .typeError('Tanggal Kelahiran harus diisi'),
  email: yup
    .string()
    .required('Email harus diisi')
    .email('Format email tidak valid'),
  phone: yup.string().matches(/^[0-9]*$/, 'Nomor telepon harus berisi angka'),

  domicileAddress: yup.string(),
  domicileZipCode: yup.string(),
  domicileProvince: yup.string(),
  domicileCity: yup.string(),
  domicileRecidenceStatus: yup.string(),

  job: yup.string().required('Pekerjaan harus diisi'),
  officeName: yup.string().required('Nama Perusahaan harus diisi'),
  businessField: yup.string().required('Bidang Perusahaan harus diisi'),
  officeZipcode: yup
    .string()
    .min(5, 'Kode pos perusahaan tidak valid')
    .required('Kode pos perusahaan harus diisi'),
  officeAddress: yup.string().required('Alamat Perusahaan harus diisi'),
  officeCity: yup.string().required('Kota Perusahaan harus diisi'),
  officePhone: yup
    .string()
    .required('Nomor Telepon Perusahaan harus diisi')
    .matches(/^[0-9]*$/, 'Nomor telepon harus berisi angka'),
  incomePerYear: yup.string().required('Penghasilan Per Tahun harus diisi'),
  incomeSource: yup.string().required('Penghasilan Per Tahun harus diisi'),

  accountOwner: yup
    .string()
    .required('Pemilik Rekening harus diisi')
    .matches(/^[a-zA-Z ]*$/, 'Pemilik rekening tidak valid'),
  bankName: yup.string().required('Nama Bank harus diisi'),
  accountNumber: yup
    .string()
    .required('Nomor Rekening harus diisi')
    .matches(/^[0-9]*$/, 'Nomor rekening harus berisi angka'),

  referalCode: yup.string(),
  agent: yup.string(),
});
