import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface Props {
  height?: number;
  width?: number;
}

export const Space: React.FC<Props> = (props) => {
  const style: StyleProp<ViewStyle> = [styles.container];
  if (props.height) {
    style.push({ height: props.height });
  }
  if (props.width) {
    style.push({ width: props.width });
  }
  return <View style={style} />;
};

const styles = StyleSheet.create({
  container: {
    height: 16,
    width: 16,
  },
});
