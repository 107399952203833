import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { FieldError } from 'react-hook-form';

import { colors } from './colors';

interface Props {
  error?: FieldError;
  message?: string;
}

export const ErrorMessage: React.FC<Props> = (props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {props.error && (props.message || props.error.message)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 16,
    marginBottom: 4,
  },
  text: {
    color: colors.red_dark,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 12,
  },
});
