import React from 'react';
import { createContext } from 'react';
import { Draft } from 'immer';
import { useImmer } from 'use-immer';

export function createStore<T>(initialState: T) {
  interface ContextValue {
    state: T;
    update: (f: (draft: Draft<T>) => void | T) => void;
  }

  const Context = createContext<ContextValue>({
    state: initialState,
    update: () => {},
  });

  const Provider: React.FC = (props) => {
    const [state, update] = useImmer<T>(initialState);
    return (
      <Context.Provider value={{ state, update }}>
        {props.children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
}
