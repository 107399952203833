import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { colors } from './colors';

interface Props {
  onPress?: () => void;
}

export const FooterButton: React.FC<Props> = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.container}>
        <Text style={styles.label}>SELANJUTNYA</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.tomato,
    height: 64,
    justifyContent: 'center',
  },
  label: {
    color: colors.white,
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: 14,
    letterSpacing: 1.58,
  },
});
