import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { colors, Header, Page, Space, Title } from '../../components';
import { RootStackParamList } from '../../navigators';
import ChecklistImage from './checklist.svg';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Complete'>;
  route: RouteProp<RootStackParamList, 'Complete'>;
}

export const CompleteScreen: React.FC<Props> = (props) => {
  return (
    <Page>
      <Header />
      <View style={styles.container}>
        <Title>Selesai!</Title>
        <Space height={80} />
        <View style={styles.image}>
          <ChecklistImage />
        </View>
        <Space height={32} />
        <Text style={styles.description}>
          Terima kasih telah mendaftar di dalam Sinarmas Sekuritas. Data Anda
          telah kami terima dan sedang dalam proses verifikasi oleh tim kami
          dalam 1 x 24 Jam
        </Text>
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  image: {
    alignItems: 'center',
  },
  description: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 18,
    textAlign: 'center',
  },
});
