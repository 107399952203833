import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { registerRootComponent, AppLoading } from 'expo';

import { useCustomFonts } from './components';
import { linking, RootStackNavigator } from './navigators';
import { AppStore } from './stores';

const App: React.FC = () => {
  const fontsLoaded = useCustomFonts();

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <AppStore.Provider>
      <NavigationContainer linking={linking}>
        <RootStackNavigator />
      </NavigationContainer>
    </AppStore.Provider>
  );
};

registerRootComponent(App);
