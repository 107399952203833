import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { colors } from './colors';

interface Props {}

export const Subtitle: React.FC<Props> = (props) => {
  return <Text style={styles.container}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  container: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: 48,
    letterSpacing: 0,
    lineHeight: 24,
    width: 327,
  },
});
