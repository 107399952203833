import axios from 'axios';

interface Result {
  ktp: string;
}

async function postOcr(base64: string): Promise<Result> {
  const result: Result = {
    ktp: '',
  };
  if (base64 !== '') {
    const url = 'https://stg-simvest.kelolaapp.com/api/v2/ocr/postOcr';
    const formData = new FormData();
    const base64response = await fetch(base64);
    const blob = await base64response.blob();
    formData.append('file', blob);
    const response = await axios.post<string>(url, formData, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQbmxaMGJHdm1jWmZpWlRzNmlkd1BJZ01BUEpGWnZKMiIsInN1YiI6IlNpbWFzSW52ZXN0In0.LLN3Yg1bnFSt0DlH7wCC0q4rfMKPezxDyCRc83EATTM',
        'Content-Type': 'multipart/form-data',
      },
    });
    const matches = response.data.match(/[\w]{10,20}/g);
    if (matches) {
      result.ktp = matches[0];
    }
  }
  return result;
}

export default {
  postOcr,
};
