import React from 'react';
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { isDesktop } from '../utils';

interface Props {
  onLayout?: (event: LayoutChangeEvent) => void;
}

export const Page: React.FC<Props> = (props) => {
  const { children } = props;
  const style: StyleProp<ViewStyle> = [styles.container];
  if (isDesktop) {
    style.push(styles.desktop);
  }
  return (
    <View style={style} onLayout={props.onLayout}>
      {children}
    </View>
  );
};

export const desktopPageWidth = 480;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    flex: 1,
  },
  desktop: {
    alignSelf: 'center',
    width: desktopPageWidth,
  },
});
