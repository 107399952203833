export const colors = {
  battleship_grey: '#637381',
  gunmetal: '#454f5b',
  ink_lightest: '#919eab',
  ink: '#212b36',
  pale_grey: '#dfe3e8',
  red_dark: '#bf0711',
  sky_dark: '#c4cdd5',
  tomato: '#de3618',
  white: '#ffffff',
};
