import axios from 'axios';

interface Data {
  zipCode: string;
}

async function getCity(data: Data): Promise<string> {
  let result = '';

  if (data.zipCode) {
    const url = `https://stg-api.siminvest.co.id/api/v1/users/zipcode?kode_pos=${data.zipCode}`;
    const response = await axios.get(url);
    result = response.data;
  }
  return result;
}

export default {
  getCity,
};
