import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';

import {
  Disclaimer,
  FooterButton,
  Header,
  Page,
  Space,
  Subtitle,
  Title,
} from '../../components';
import { RootStackParamList } from '../../navigators';
import { PhoneInputField } from './phone-input-field';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'PhoneInput'>;
}

export const PhoneInputScreen: React.FC<Props> = (props) => {
  return (
    <Page>
      <Header onBack={() => props.navigation.goBack()} />
      <ScrollView contentContainerStyle={styles.container}>
        <Space height={40} />
        <Title small>Masukkan nomor ponsel untuk memulai</Title>
        <Space height={8} />
        <Subtitle>
          Kami Perlu mengirimkan OTP untuk memverifikasi Pembukaan Akun Anda
        </Subtitle>
        <Space height={32} />
        <PhoneInputField />
      </ScrollView>
      <Disclaimer />
      <Space height={24} />
      <FooterButton />
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
});
