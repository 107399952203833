import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import RotateIcon from './rotate.svg';

interface Props {
  onPress: () => void;
}

export const RotateButton: React.FC<Props> = (props) => (
  <TouchableOpacity onPress={props.onPress}>
    <View style={styles.container}>
      <RotateIcon />
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: 24,
    height: 48,
    justifyContent: 'center',
    width: 48,
  },
});
