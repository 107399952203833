export const linking = {
  prefixes: [],
  config: {
    screens: {
      Camera: 'camera',
      Complete: 'complete',
      IdVerification: 'id-verification',
      Landing: 'landing',
      Otp: 'Otp',
      PhoneInput: 'phone-input',
      Registration: 'registration',
      Signature: 'signature',
    },
  },
};
