import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { colors } from './colors';

interface Props {}

export const Disclaimer: React.FC<Props> = (props) => {
  return (
    <View>
      <Text style={styles.text}>Dengan mendaftar, Anda telah menyetujui </Text>
      <Text style={styles.text}>
        <Text style={styles.link} onPress={() => alert('aturan')}>
          Aturan Penggunaan
        </Text>
        {' dan '}
        <Text style={styles.link} onPress={() => alert('kebijakan')}>
          Kebijakan Privasi
        </Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 14,
    textAlign: 'center',
  },
  link: {
    color: colors.tomato,
  },
});
