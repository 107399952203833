import React from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';

import { colors } from '../../../components';

import CheckboxBlankIcon from './checkbox-blank.svg';
import CheckboxMarkedIcon from './checkbox-marked.svg';

interface Props {
  value: boolean;
  onChangeValue: (value: boolean) => void;
}

export const Checkbox: React.FC<Props> = (props) => {
  return (
    <TouchableWithoutFeedback onPress={() => props.onChangeValue(!props.value)}>
      <View style={styles.container}>
        {props.value ? <CheckboxMarkedIcon /> : <CheckboxBlankIcon />}
        <Text style={styles.label}>Alamat domisili sesuai dengan KTP</Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  label: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
    marginLeft: 8,
  },
});
