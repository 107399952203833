import React from 'react';
import { Image, Text, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';

import { colors, Button, Page, Space, Title } from '../components';
import { RootStackParamList } from '../navigators';

import SekuritasSinarmasLogo from '../../assets/logos/sekuritas-sinarmas.png';
import SiminvestLogo from '../../assets/logos/siminvest.png';
import OjkLogo from '../../assets/logos/ojk.png';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Landing'>;
}

export const LandingScreen: React.FC<Props> = (props) => {
  return (
    <Page>
      <View style={styles.container}>
        <Space height={60} />
        <Image
          source={SekuritasSinarmasLogo}
          style={{ width: 129, height: 32 }}
        />
        <Space height={24} />
        <Title>Pembukaan Rekening Saham Online</Title>
        <Space height={80} />
        <Button
          label="BUKA AKUN TRADING"
          onPress={() => props.navigation.navigate('IdVerification')}
        />
        <Space />
        <View style={styles.row}>
          <Text style={styles.text}>Powered by</Text>
          <Space width={5} />
          <Image source={SiminvestLogo} style={{ width: 68, height: 12 }} />
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.text}>Terdaftar dan diawasi oleh</Text>
        <Space width={10} />
        <Image source={OjkLogo} style={{ height: 23, width: 57 }} />
      </View>
      <Space height={36} />
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
  },
  text: {
    color: colors.gunmetal,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 14,
    lineHeight: 16,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
