import React from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
} from 'react-native';

import { colors, Field, Space } from '../../components';

interface Props {
  value?: string;
  onChangeText?: (text: string) => void;
}

export const PhoneInputField: React.FC<Props> = (props) => {
  const textInputStyle: StyleProp<TextStyle> = [styles.textInput];
  if (Platform.OS === 'web') {
    textInputStyle.push({ outline: 'none' } as any);
  }
  return (
    <Field label="Nomor Ponsel" required>
      <Text style={styles.prefix}>+62</Text>
      <Space />
      <View style={styles.input}>
        <TextInput
          keyboardType="phone-pad"
          style={textInputStyle}
          value={props.value}
          onChangeText={props.onChangeText}
        />
      </View>
    </Field>
  );
};

const styles = StyleSheet.create({
  prefix: {
    color: colors.ink_lightest,
    fontFamily: 'SFProDisplay-Bold',
    fontSize: 18,
    textAlignVertical: 'center',
  },
  input: {
    flex: 1,
  },
  textInput: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Bold',
    fontSize: 18,
  },
});
