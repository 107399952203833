import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { CameraCapturedPicture } from 'expo-camera';

import {
  CameraScreen,
  CompleteScreen,
  IdVerificationScreen,
  LandingScreen,
  OtpScreen,
  PhoneInputScreen,
  RegistrationScreen,
  SignatureScreen,
} from '../screens';

export type RootStackParamList = {
  Camera: {
    mask?: 'id-card' | 'selfie';
    screen: keyof RootStackParamList;
    field: 'idPhoto' | 'selfiePhoto' | 'npwpPhoto';
  };
  Complete: undefined;
  IdVerification?: {
    field: string;
    picture: string;
  };
  Landing: undefined;
  Otp: undefined;
  PhoneInput: undefined;
  Registration?: {
    field?: string;
    picture?: string;
    signature?: string;
  };
  Signature?: { field: string; screen: keyof RootStackParamList };
};

const RootStack = createStackNavigator<RootStackParamList>();

export const RootStackNavigator: React.FC = () => (
  <RootStack.Navigator initialRouteName="Landing" headerMode="none">
    <RootStack.Screen name="Camera" component={CameraScreen} />
    <RootStack.Screen name="Complete" component={CompleteScreen} />
    <RootStack.Screen name="IdVerification" component={IdVerificationScreen} />
    <RootStack.Screen name="Landing" component={LandingScreen} />
    <RootStack.Screen name="Otp" component={OtpScreen} />
    <RootStack.Screen name="PhoneInput" component={PhoneInputScreen} />
    <RootStack.Screen name="Registration" component={RegistrationScreen} />
    <RootStack.Screen name="Signature" component={SignatureScreen} />
  </RootStack.Navigator>
);
