import React, { useEffect, useState, useContext } from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { AppStore } from '../../stores';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import dayjs from 'dayjs';

import ChevronRightIcon from '../../../assets/icons/chevron-right.svg';
import { default as input } from '../../../assets/input/input.json';
import {
  colors,
  Disclaimer,
  FooterButton,
  Header,
  Page,
  Space,
  ErrorMessage,
} from '../../components';

import { RootStackParamList } from '../../navigators';
import { Checkbox } from './checkbox';
import { DateInputField, SelectInputField, TextInputField } from './fields';
import { schema } from './schema';
import api from '../../api';
import { getBankList, resizeFile } from '../../utils';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Registration'>;
  route: RouteProp<RootStackParamList, 'Registration'>;
}

interface Data {
  ktp: string;
  dob: string;

  email: string;
  phone: string;
  domicileAddress: string;
  domicileZipCode: string;
  domicileProvince: string;
  domicileCity: string;
  domicileRecidenceStatus: string;

  job: string;
  officeName: string;
  businessField: string;
  officeZipcode: string;
  officeAddress: string;
  officeCity: string;
  officePhone: string;
  incomePerYear: string;
  incomeSource: string;

  accountOwner: string;
  bankName: string;
  accountNumber: string;

  riskType: string;

  referalCode: string;
  agent: string;
}

export const RegistrationScreen: React.FC<Props> = (props, { text }) => {
  const appStore = useContext(AppStore.Context);
  const { handleSubmit, control, errors, getValues, setValue } = useForm<Data>({
    resolver: yupResolver(schema),
  });
  const [yesNoRadio] = useState([
    { label: 'Ya', value: 'Ya' },
    { label: 'Tidak', value: 'Tidak' },
  ]);
  const [recidenceStatusList] = useState([
    { label: 'Milik Sendiri', value: 'Milik Sendiri' },
    { label: 'Kos', value: 'Kos' },
  ]);
  const [agentList] = useState([
    { label: '', value: '' },
    { label: 'Agent A', value: 'Agent A' },
    { label: 'Agent B', value: 'Agent B' },
    { label: 'Agent C', value: 'Agent C' },
    { label: 'Agent D', value: 'Agent D' },
  ]);
  const [jobList] = useState(input.occupation);
  const [businessFieldList] = useState(input.industry);
  const [incomeList] = useState(input.income);
  const [incomeSourceList] = useState(input.income_source);
  const [bankList, setBankList] = useState<Object>();
  const [riskTypeList] = useState([
    { label: 'Resiko rendah, tingkat return rendah', value: 'Risk Averse' },
    { label: 'Resiko sedang, tingkat return sedang', value: 'Balance' },
    { label: 'Resiko tinggi, Tingkat return tinggi', value: 'Agresif' },
  ]);

  const [domicileSame, setDomicileSame] = useState(true);
  const [domicileRecidenceStatus, setDomicileRecidenceStatus] = useState(
    'Milik Sendiri'
  );
  const [job, setJob] = useState('Pegawai Swasta');
  const [businessField, setBusinessField] = useState('Air');
  const [incomePerYear, setIncomePerYear] = useState('<25');
  const [incomeSource, setIncomeSource] = useState('Hasil Usaha');
  const [riskType, setRiskType] = useState('Risk Averse');

  const [bankName, setBankName] = useState('Bank Sinarmas');
  const [agent, setAgent] = useState('');

  useEffect(() => {
    async function run() {
      const newBankList = await getBankList();
      if (newBankList) {
        setBankList(newBankList);
      }
    }
    if (!bankList) {
      run();
    }
  });

  function navigateCamera(field: keyof RootStackParamList) {
    props.navigation.navigate('Camera', {
      screen: 'Registration',
      field,
    });
  }

  function navigateSignature(field: string) {
    props.navigation.navigate('Signature', {
      screen: 'Registration',
      field,
    });
  }

  const submit = handleSubmit(async (data) => {
    if (data) {
      const values = getValues();
      let idPhotoPath = null;

      if (appStore.state.cameraResult.idPhoto) {
        const base64response = await fetch(appStore.state.cameraResult.idPhoto);
        const blob = await base64response.blob();
        const compressedImage = await resizeFile(blob);
        const idPhotoPayload = {
          base64: compressedImage,
          nik: values.ktp,
          keys: 'ktp',
        };
        idPhotoPath = await api.uploadPhoto.uploadPhoto(idPhotoPayload);
      }

      let npwpPhotoPath = null;
      if (appStore.state.cameraResult.npwpPhoto) {
        const base64response = await fetch(
          appStore.state.cameraResult.npwpPhoto
        );
        const blob = await base64response.blob();
        const compressedImage = await resizeFile(blob);
        const npwpPhotoPayload = {
          base64: compressedImage,
          nik: values.ktp,
          keys: 'npwp',
        };
        npwpPhotoPath = await api.uploadPhoto.uploadPhoto(npwpPhotoPayload);
      }

      let selfiePhotoPath = null;
      if (appStore.state.cameraResult.selfiePhoto) {
        const base64response = await fetch(
          appStore.state.cameraResult.selfiePhoto
        );
        const blob = await base64response.blob();
        const compressedImage = await resizeFile(blob);
        const selfiePhotoPayload = {
          base64: compressedImage,
          nik: values.ktp,
          keys: 'selfie',
        };
        selfiePhotoPath = await api.uploadPhoto.uploadPhoto(selfiePhotoPayload);
      }

      let signaturePath = null;
      if (appStore.state.signatureResult.signature) {
        const base64response = await fetch(
          appStore.state.signatureResult.signature
        );
        const blob = await base64response.blob();
        const compressedImage = await resizeFile(blob);
        const signaturePayload = {
          base64: compressedImage,
          nik: values.ktp,
          keys: 'signature',
        };
        signaturePath = await api.uploadPhoto.uploadPhoto(signaturePayload);
      }

      const payload = {
        id_number: data.ktp,
        date_of_birth: dayjs(data.dob).format('DD-MM-YYYY'),
        id_type: 'ktp',
        method: 'both',
        email: data.email,
        phone_number: data.phone,

        domicile_same: domicileSame,
        bank_details: {
          account_number: data.accountNumber,
          bank_name: data.bankName,
          name: data.accountOwner,
        },

        occupation_details: {
          company_address: data.officeAddress,
          company_city: data.officeCity,
          company_industry: data.businessField,
          company_name: data.officeName,
          company_phone: data.officePhone,
        },

        risk_type: data.riskType,
        rdn: {
          account_number: '',
          bank_name: '',
          q_company: false,
          q_company_response: {
            company_name: '',
          },
          q_other_account: false,
          q_other_account_response: {
            company_name: '',
          },
          q_own_five: false,
          q_own_five_response: {
            company_code: '',
            company_name: '',
          },
          q_own_thirtyfive: false,
          q_own_thirtyfive_response: {
            company_name: '',
            title: '',
          },
          q_politics: false,
          q_politics_response: {
            party_name: '',
            title: '',
          },
          q_us_tax: false,
          q_us_tax_response: {
            tin: '',
          },
        },
        selfie_image_path: selfiePhotoPath ? selfiePhotoPath?.path : '',
        signature_image_path: signaturePath ? signaturePath?.path : '',
        id_image_path: idPhotoPath ? idPhotoPath?.path : '',
        npwp_image_path: npwpPhotoPath ? npwpPhotoPath?.path : '',
        agent: '',
      };
      if (domicileSame) {
        payload['address_detail'] = {
          domicile_address: data.domicileAddress,
          domicile_zip_code: data.domicileZipCode,
          domicile_province: data.domicileProvince,
          domicile_city: data.domicileCity,
          domicile_status: data.domicileRecidenceStatus,
        };
      }
      console.log('payload', payload);
      api.submitKyc.submitKyc(payload).then((result) => {
        console.log('result', result);
        props.navigation.navigate('Complete');
      });
    }
  });

  async function doGetCity(text: string) {
    const zipCode = {
      zipCode: text,
    };
    if (text.length == 5) {
      try {
        const result = await api.getCity.getCity(zipCode);
        setValue('officeCity', result?.data[0]?.kota_kabupaten);
      } catch (err) {
        console.log('error', err);
      }
    }
  }
  console.log('ini testing ya jgn dianggep serius..');
  console.log('ini baru lagi ya..');
  return (
    <Page>
      <Header onBack={() => props.navigation.goBack()} />
      <ScrollView>
        <View style={styles.container}>
          <Text style={styles.title}>Registrasi</Text>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>PERSONAL INFORMATION</Text>
            {/* KTP */}
            <Controller
              control={control}
              name="ktp"
              defaultValue={appStore.state.personalInfo.idNumber}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Nomor KTP"
                  required={true}
                  fieldType="phone-pad"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                  maxLength={16}
                  changeBtn={true}
                  placeholder="123456789"
                  isDissabled={true}
                  onPressChange={() =>
                    props.navigation.navigate('IdVerification')
                  }
                />
              )}
            />
            <ErrorMessage error={errors.ktp} message={errors.ktp?.message} />
            {/* --KTP-- */}

            {/* DOB */}
            <Controller
              control={control}
              name="dob"
              defaultValue={appStore.state.personalInfo.dateOfBirth}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <DateInputField
                  title="Tanggal Lahir"
                  required={true}
                  value={value}
                  onChangeText={(text) => onChange(text)}
                  placeholder="DD - MM - YYYY"
                  isDissabled={true}
                />
              )}
            />
            <ErrorMessage error={errors.dob} message={errors.dob?.message} />
            {/* --DOB-- */}
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>CONTACT INFORMATION</Text>
            {/* Phone */}
            <Controller
              control={control}
              name="phone"
              defaultValue=""
              render={({ onChange, value }) => (
                <TextInputField
                  title="Nomor Ponsel"
                  required={false}
                  fieldType="phone-pad"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                  prefix="+62"
                  maxLength={14}
                />
              )}
            />
            <ErrorMessage
              error={errors.phone}
              message={errors.phone?.message}
            />
            {/* --Phone-- */}

            {/* Email */}
            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Alamat Email"
                  required={true}
                  fieldType="default"
                  value={value}
                  placeholder="email@email.com"
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
            <ErrorMessage
              error={errors.email}
              message={errors.email?.message}
            />
            {/* --Email-- */}

            <Checkbox value={domicileSame} onChangeValue={setDomicileSame} />
            <Space />
            {!domicileSame && (
              <View>
                {/* Domicile Address */}
                <Controller
                  control={control}
                  name="domicileAddress"
                  defaultValue=""
                  rules={{ required: !domicileSame ? true : false }}
                  render={({ onChange, value }) => (
                    <TextInputField
                      title="Alamat Domisili"
                      required={true}
                      fieldType="default"
                      value={value}
                      onChangeText={(text) => onChange(text)}
                    />
                  )}
                />
                <ErrorMessage
                  error={errors.domicileAddress}
                  message={errors.domicileAddress?.message}
                />
                {/* --Domicile Address-- */}

                {/* Domicile Zip Code */}
                <Controller
                  control={control}
                  name="domicileZipCode"
                  defaultValue=""
                  rules={{ required: !domicileSame ? true : false }}
                  render={({ onChange, value }) => (
                    <TextInputField
                      title="Kodepos"
                      required={true}
                      fieldType="default"
                      value={value}
                      onChangeText={(text) => onChange(text)}
                      maxLength={7}
                    />
                  )}
                />
                <ErrorMessage
                  error={errors.domicileZipCode}
                  message={errors.domicileZipCode?.message}
                />
                {/* --Domicile Zip Code-- */}

                {/* Domicile Province */}
                <Controller
                  control={control}
                  name="domicileProvince"
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextInputField
                      title="Provinsi"
                      required={false}
                      fieldType="default"
                      value={value}
                      onChangeText={(text) => onChange(text)}
                    />
                  )}
                />
                <ErrorMessage
                  error={errors.domicileProvince}
                  message={errors.domicileProvince?.message}
                />
                {/* --Domicile Province-- */}

                {/* Domicile City */}
                <Controller
                  control={control}
                  name="domicileCity"
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <TextInputField
                      title="Kota"
                      required={false}
                      fieldType="default"
                      value={value}
                      onChangeText={(text) => onChange(text)}
                    />
                  )}
                />
                <ErrorMessage
                  error={errors.domicileCity}
                  message={errors.domicileCity?.message}
                />
                {/* --Domicile City-- */}

                {/* Domicile Recidence Status */}
                <Controller
                  control={control}
                  name="domicileRecidenceStatus"
                  defaultValue={domicileRecidenceStatus}
                  rules={{ required: !domicileSame ? true : false }}
                  render={({ onChange }) => (
                    <SelectInputField
                      title="Status Tempat Tinggal"
                      required={true}
                      pickerProps={recidenceStatusList}
                      onChange={(value) => {
                        setDomicileRecidenceStatus(value);
                        onChange(value);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  error={errors.domicileRecidenceStatus}
                  message={errors.domicileRecidenceStatus?.message}
                />
                {/* --Domicile Recidence Status-- */}
              </View>
            )}
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>OCCUPATION</Text>
            {/* Job */}
            <Controller
              control={control}
              name="job"
              defaultValue={job}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Pekerjaan"
                  required={true}
                  pickerProps={jobList}
                  onChange={(value) => {
                    setJob(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage error={errors.job} message={errors.job?.message} />
            {/* --Job-- */}

            {/* Office Name */}
            <Controller
              control={control}
              name="officeName"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Nama Perusahaan"
                  required={true}
                  fieldType="default"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
            <ErrorMessage
              error={errors.officeName}
              message={errors.officeName?.message}
            />
            {/* --Office Name-- */}

            {/* Business Field */}
            <Controller
              control={control}
              name="businessField"
              defaultValue={businessField}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Bidang Usaha"
                  required={true}
                  pickerProps={businessFieldList}
                  onChange={(value) => {
                    setBusinessField(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.businessField}
              message={errors.businessField?.message}
            />
            {/* --Business Field-- */}

            {/* Office Address */}
            <Controller
              control={control}
              name="officeAddress"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Alamat Perusahaan"
                  required={true}
                  fieldType="default"
                  value={value}
                  onChangeText={(text) => {
                    onChange(text);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.officeAddress}
              message={errors.officeAddress?.message}
            />
            {/* --Office Address-- */}

            {/* Office Zipcode */}
            <Controller
              control={control}
              name="officeZipcode"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Kode Pos Perusahaan"
                  required={true}
                  fieldType="default"
                  value={value}
                  maxLength={5}
                  onChangeText={(text) => {
                    onChange(text);
                    doGetCity(text);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.officeZipcode}
              message={errors.officeZipcode?.message}
            />
            {/* --Office Zipcode-- */}

            {/* Office City */}
            <Controller
              control={control}
              name="officeCity"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Kota"
                  required={true}
                  fieldType="default"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                  isDissabled={true}
                />
              )}
            />
            <ErrorMessage
              error={errors.officeCity}
              message={errors.officeCity?.message}
            />
            {/* --Office City-- */}

            {/* Office Phone */}
            <Controller
              control={control}
              name="officePhone"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Nomor Telepon Perusahaan"
                  required={true}
                  fieldType="phone-pad"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                  maxLength={14}
                />
              )}
            />
            <ErrorMessage
              error={errors.officePhone}
              message={errors.officePhone?.message}
            />
            {/* --Office Phone-- */}

            {/* Income Per Year */}
            <Controller
              control={control}
              name="incomePerYear"
              defaultValue={incomePerYear}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Penghasilan Per Tahun"
                  required={true}
                  pickerProps={incomeList}
                  onChange={(value) => {
                    setIncomePerYear(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.incomePerYear}
              message={errors.incomePerYear?.message}
            />
            {/* --Income Per Year-- */}

            {/* Income Source */}
            <Controller
              control={control}
              name="incomeSource"
              defaultValue={incomeSource}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Sumber Penghasilan"
                  required={true}
                  pickerProps={incomeSourceList}
                  onChange={(value) => {
                    setIncomeSource(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.incomeSource}
              message={errors.incomeSource?.message}
            />
            {/* --Income Source-- */}
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>BANK ACCOUNT</Text>
            {/* Account Owner */}
            <Controller
              control={control}
              name="accountOwner"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Pemilik Rekening"
                  required={true}
                  fieldType="default"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
            <ErrorMessage
              error={errors.accountOwner}
              message={errors.accountOwner?.message}
            />
            {/* --Account Owner-- */}

            {/* Bank Name */}
            <Controller
              control={control}
              name="bankName"
              defaultValue={bankName}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Nama Bank"
                  required={true}
                  pickerProps={bankList}
                  onChange={(value) => {
                    setBankName(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage
              error={errors.bankName}
              message={errors.bankName?.message}
            />
            {/* --Bank Name-- */}

            {/* Account Number */}
            <Controller
              control={control}
              name="accountNumber"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputField
                  title="Nomor Rekening"
                  required={true}
                  fieldType="phone-pad"
                  value={value}
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
            <ErrorMessage
              error={errors.accountNumber}
              message={errors.accountNumber?.message}
            />
            {/* --Account Number-- */}
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>PROFIL RESIKO</Text>
            {/* Risk Type */}
            <Controller
              control={control}
              name="riskType"
              defaultValue={riskType}
              rules={{ required: true }}
              render={({ onChange }) => (
                <SelectInputField
                  title="Gaya Investasi"
                  required={true}
                  pickerProps={riskTypeList}
                  onChange={(value) => {
                    setRiskType(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <ErrorMessage error={errors.job} message={errors.job?.message} />
            {/* --Risk Type-- */}
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>UPLOAD DOCUMENTS</Text>
            <View style={styles.uploadContainer}>
              <TouchableWithoutFeedback
                onPress={() => navigateCamera('idPhoto')}
              >
                <View style={styles.inputWrapper}>
                  {appStore.state.cameraResult.idPhoto !== '' && (
                    <Image
                      source={{ uri: appStore.state.cameraResult.idPhoto }}
                      style={styles.photoField}
                    />
                  )}
                  <Text style={styles.label}>
                    Foto KTP<Text style={styles.required}>*</Text>
                  </Text>

                  <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                </View>
              </TouchableWithoutFeedback>

              <TouchableWithoutFeedback
                onPress={() => navigateCamera('npwpPhoto')}
              >
                <View style={styles.inputWrapper}>
                  {appStore.state.cameraResult.npwpPhoto !== '' && (
                    <Image
                      source={{ uri: appStore.state.cameraResult.npwpPhoto }}
                      style={styles.photoField}
                    />
                  )}
                  <Text style={styles.label}>Foto NPWP (Optional)</Text>

                  <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback
                onPress={() => navigateCamera('selfiePhoto')}
              >
                <View style={styles.inputWrapperWithoutBorder}>
                  {appStore.state.cameraResult.selfiePhoto !== '' && (
                    <Image
                      source={{ uri: appStore.state.cameraResult.selfiePhoto }}
                      style={styles.photoField}
                    />
                  )}
                  <Text style={styles.label}>
                    Foto Selfie <Text style={styles.required}>*</Text>
                  </Text>
                  <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
          <View style={styles.categoryContainer}>
            <Text style={styles.categoryTitle}>SIGNATURE </Text>
            <TouchableWithoutFeedback
              onPress={() => navigateSignature('signature')}
            >
              <View style={styles.pad}>
                {appStore.state.signatureResult.signature !== '' && (
                  <Image
                    source={{ uri: appStore.state.signatureResult.signature }}
                    style={styles.signatureCanvas}
                  />
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>

          {/* Referal Code */}
          <Controller
            control={control}
            name="referalCode"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <TextInputField
                title="Kode Referal (Optional)"
                required={false}
                fieldType="default"
                value={value}
                onChangeText={(text) => onChange(text)}
              />
            )}
          />
          <ErrorMessage
            error={errors.referalCode}
            message={errors.referalCode?.message}
          />
          {/* --Referal Code-- */}
          {/* Agent */}
          <Controller
            control={control}
            name="agent"
            defaultValue=""
            rules={{ required: false }}
            render={({ onChange }) => (
              <SelectInputField
                title="Sales Agent"
                required={false}
                pickerProps={agentList}
                onChange={(value) => {
                  setAgent(value);
                  onChange(value);
                }}
              />
            )}
          />
          {/* --Agent-- */}
        </View>
        <Disclaimer />
        <Space height={24} />
        <FooterButton
          onPress={submit}
          //  onPress={() => props.navigation.navigate('Complete')}
        />
      </ScrollView>
    </Page>
  );
};

const styles = StyleSheet.create({
  signatureCanvas: {
    height: '100%',
    width: '100%',
  },
  pad: {
    flex: 1,
    marginTop: 5,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#c4cdd5',
    marginHorizontal: 5,
    borderRadius: 5,
    height: 200,
    minHeight: 200,
  },
  container: {
    padding: 24,
  },
  uploadContainer: {
    borderColor: colors.sky_dark,
    borderWidth: 1,
    marginTop: 10,
    padding: 10,
    borderRadius: 5,
  },
  categoryContainer: {
    marginTop: 30,
  },
  categoryTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    color: colors.gunmetal,
    fontFamily: 'SFProDisplay-Semibold',
    letterSpacing: 0.4,
  },
  title: {
    fontSize: 34,
    fontWeight: 'bold',
    fontFamily: 'SFProDisplay-Semibold',
    color: colors.ink,
  },
  label: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
  },
  required: {
    color: colors.tomato,
  },
  inputWrapper: {
    minHeight: 40,
    maxHeight: 40,
    flexDirection: 'row',
    borderBottomColor: '#c4cdd5',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    marginBottom: 4,
  },
  inputWrapperWithoutBorder: {
    minHeight: 40,
    maxHeight: 40,
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    marginBottom: 4,
  },
  field: {
    paddingTop: 16,
  },
  photoField: {
    width: 55,
    height: 35,
    marginRight: 10,
  },
});
