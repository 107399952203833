import ocr from './ocr';
import users from './users';
import getCity from './get-city';
import uploadPhoto from './upload-photo';
import bankList from './bank-list';
import submitKyc from './submit-kyc';

export default {
  ocr,
  users,
  getCity,
  uploadPhoto,
  bankList,
  submitKyc,
};
