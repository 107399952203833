import React from 'react';
import {
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import ArrowLeftWhiteIcon from '../../assets/icons/arrow-left-white.svg';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';

interface Props {
  dark?: boolean;
  title?: string;
  onBack?: () => void;
}

export const Header: React.FC<Props> = (props) => {
  return (
    <View>
      <View style={{ height: StatusBar.currentHeight }} />
      <View style={styles.container}>
        {props.onBack ? (
          <TouchableOpacity onPress={props.onBack}>
            <View style={styles.iconButton}>
              {props.dark ? <ArrowLeftWhiteIcon /> : <ArrowLeftIcon />}
            </View>
          </TouchableOpacity>
        ) : (
          <View style={styles.iconButton} />
        )}
        <View style={styles.content}>
          <Text>{props.title}</Text>
        </View>
        <View style={styles.iconButton} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  content: {
    alignItems: 'center',
    flex: 1,
  },
  iconButton: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    width: 72,
  },
});
