import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { Camera } from 'expo-camera';

import { Header, Page } from '../../components';
import { RootStackParamList } from '../../navigators';
import { AppStore } from '../../stores';
import { getPageWidth, isDesktop } from '../../utils';
import { CaptureButton } from './capture-button';
import { IdCardMask, SelfieMask } from './masks';
import { RotateButton } from './rotate-button';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Camera'>;
  route: RouteProp<RootStackParamList, 'Camera'>;
}

export const CameraScreen: React.FC<Props> = (props) => {
  const appStore = useContext(AppStore.Context);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [showCamera, setShowCamera] = useState(true);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [cameraStyle, setCameraStyle] = useState({});

  const cameraRef = useRef<Camera>(null);

  if (Platform.OS !== 'web') {
    useEffect(() => {
      (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
      })();
    }, []);

    if (hasPermission === null) {
      return <View />;
    }

    if (hasPermission === false) {
      return <Text>No access to camera</Text>;
    }
  }

  function handlePageLayout(event: LayoutChangeEvent) {
    if (Platform.OS !== 'web') {
      const pageHeight = event.nativeEvent.layout.height;
      const pageWidth = event.nativeEvent.layout.width;
      if (pageHeight > pageWidth) {
        const cameraWidth = (3 / 4) * pageHeight;
        setCameraStyle({
          height: pageHeight,
          width: cameraWidth,
        });
      }
    }
  }

  function switchCamera() {
    setShowCamera(false);
    if (type === Camera.Constants.Type.back) {
      setType(Camera.Constants.Type.front);
    } else {
      setType(Camera.Constants.Type.back);
    }
    setTimeout(() => {
      setShowCamera(true);
    }, 100);
  }

  async function takePicture() {
    const picture = await cameraRef.current?.takePictureAsync();
    if (picture) {
      const { field } = props.route.params;
      setShowCamera(false);
      appStore.update((draft) => {
        draft.cameraResult[field] = picture.uri;
      });
      props.navigation.goBack();
    }
  }

  const maskMap = {
    'id-card': <IdCardMask />,
    selfie: <SelfieMask />,
  };

  return (
    <Page onLayout={handlePageLayout}>
      {showCamera && (
        <Camera
          ref={cameraRef}
          style={[styles.camera, cameraStyle]}
          type={type}
        >
          <Header dark onBack={() => props.navigation.goBack()} />
        </Camera>
      )}
      <View style={styles.overlay}>
        {props.route.params.mask && maskMap[props.route.params.mask]}
        <View style={styles.row}>
          <View style={styles.col}></View>
          <View style={styles.col}>
            <CaptureButton onPress={takePicture} />
          </View>
          <View style={styles.col}>
            {!isDesktop && <RotateButton onPress={switchCamera} />}
          </View>
        </View>
      </View>
    </Page>
  );
};

const markerWidth = getPageWidth() - 32;

const styles = StyleSheet.create({
  camera: {
    flex: 1,
  },
  overlay: {
    bottom: 0,
    justifyContent: 'flex-end',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 44,
  },
  mask: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  marker: {
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 8,
    borderWidth: 4,
    height: 0.63 * markerWidth,
    width: markerWidth,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 24,
  },
  col: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});
