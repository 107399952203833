import React from 'react';
import {
  Picker,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { colors, Space } from '../../../components';

interface Props {
  bottomText?: string;
  changeBtn?: Boolean;
  fieldType: string;
  maxLength?: Number;
  placeholder?: string;
  prefix?: string;
  required?: Boolean;
  title?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  onPressChange?: () => void;
  isDissabled?: Boolean;
}

export const TextInputField: React.FC<Props> = (props: any) => {
  const textInputStyle: StyleProp<TextStyle> = [styles.textInput];
  const { fieldType, maxLength } = props;
  if (Platform.OS === 'web') {
    textInputStyle.push({
      outline: 'none',
      outlineStyle: 'none',
      outlineWidth: 0,
      outlineColor: 'transparent',
    } as any);
  }
  if (props.isDissabled) {
    textInputStyle.push({
      color: colors.ink_lightest,
    });
  }
  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.label}>
          {props.title}{' '}
          {props.required && <Text style={styles.required}>*</Text>}
        </Text>
        <Space height={8} />
        <View style={styles.input}>
          {props.prefix && <Text style={styles.prefix}>+62</Text>}
          <View style={styles.inputRight}>
            <TextInput
              editable={!props.isDissabled}
              keyboardType={fieldType ? fieldType : 'default'}
              style={textInputStyle}
              value={props.value}
              onChangeText={props.onChangeText}
              maxLength={maxLength}
              placeholder={props.placeholder}
            />
          </View>
          {props.changeBtn && (
            <TouchableWithoutFeedback onPress={() => props.onPressChange()}>
              <Text style={styles.changeBtn}>Ganti</Text>
            </TouchableWithoutFeedback>
          )}
        </View>
      </View>
      {props.bottomText && (
        <Text style={styles.bottomText}>{props.bottomText}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  changeBtn: {
    fontFamily: 'SFProDisplay-Regular',
    color: colors.tomato,
    fontWeight: '600',
  },
  container: {
    borderBottomWidth: 1,
    borderColor: colors.pale_grey,
    paddingBottom: 8,
    paddingTop: 12,
  },
  label: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
  },
  required: {
    color: colors.tomato,
  },
  input: {
    flexDirection: 'row',
  },
  inputRight: {
    flex: 1,
  },
  prefix: {
    color: colors.ink_lightest,
    fontFamily: 'SFProDisplay-Bold',
    fontSize: 18,
    textAlignVertical: 'center',
    marginRight: 10,
  },
  textInput: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: 18,
  },
  bottomText: {
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    color: colors.battleship_grey,
  },
});
