import React, { useState } from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

const values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '<-'];

interface Props {
  onSelectValue: (value: string) => void;
}

export const DigitPad: React.FC<Props> = (props) => {
  const [buttonWidth, setButtonWidth] = useState(0);

  function handleLayout(event: LayoutChangeEvent) {
    const newButtonWidth = (event.nativeEvent.layout.width - 32) / 3;
    setButtonWidth(newButtonWidth);
  }

  return (
    <View style={styles.container} onLayout={handleLayout}>
      <View style={styles.row}>
        {values.map((value) => (
          <TouchableOpacity
            style={[styles.button, { width: buttonWidth }]}
            onPress={() => props.onSelectValue(value)}
          >
            <Text style={styles.label}>{value}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#D2D6DC',
    paddingVertical: 4,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: 4,
    height: 48,
    justifyContent: 'center',
    margin: 4,
  },
  label: {
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 25,
  },
});
