import React from 'react';
import { Picker, StyleSheet, Text, View } from 'react-native';

import { colors } from '../../../components';

interface Props {
  bottomText?: string;
  pickerProps?: Array<Object>;
  required?: Boolean;
  selected?: Number;
  title?: string;
  onChange: (value: string) => void;
}

export const SelectInputField: React.FC<Props> = (props) => {
  return (
    <View>
      <View style={styles.container}>
        <Text style={styles.label}>
          {props.title}{' '}
          {props.required && <Text style={styles.required}>*</Text>}
        </Text>
        <Picker
          style={{
            height: 50,
            width: '100%',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          }}
          onValueChange={(itemValue, itemIndex) => props.onChange(itemValue)}
        >
          {props.pickerProps &&
            props.pickerProps.map((obj: any, i) => (
              <Picker.Item label={obj.label} value={obj.value} key={i} />
            ))}
        </Picker>
      </View>
      {props.bottomText && (
        <Text style={styles.bottomText}>{props.bottomText}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: colors.pale_grey,
    paddingBottom: 8,
    paddingTop: 12,
  },
  label: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
  },
  required: {
    color: colors.tomato,
  },
  bottomText: {
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    color: colors.battleship_grey,
  },
});
