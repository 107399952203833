import axios from 'axios';

async function submitKyc(data): Promise<string> {
  let result = '';
  if (data) {
    const url = 'https://api.siminvest.co.id/api/v2/webview/kyc/kyc';
    const response = await axios.post<string>(url, data, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQbmxaMGJHdm1jWmZpWlRzNmlkd1BJZ01BUEpGWnZKMiIsInN1YiI6IlNpbWFzSW52ZXN0In0.LLN3Yg1bnFSt0DlH7wCC0q4rfMKPezxDyCRc83EATTM',
        'Content-Type': 'application/json',
      },
    });
    result = response.data;
  }
  return result;
}

export default {
  submitKyc,
};
