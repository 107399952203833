import { Dimensions } from 'react-native';

import { desktopPageWidth } from '../components';
import { isDesktop } from './is-desktop';

export function getPageWidth() {
  if (isDesktop) {
    return desktopPageWidth;
  }
  return Dimensions.get('window').width;
}
