import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { colors } from './colors';

interface Props {
  small?: boolean;
}

export const Title: React.FC<Props> = (props) => {
  return (
    <Text style={props.small ? styles.small : styles.container}>
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  container: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Heavy',
    fontSize: 32,
    letterSpacing: -0.3,
    lineHeight: 40,
  },
  small: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Bold',
    fontSize: 28,
    letterSpacing: -0.3,
    lineHeight: 32,
  },
});
