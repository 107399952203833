import axios from 'axios';

async function getBankList(): Promise<string> {
  let result = '';

  const url = `https://api.siminvest.co.id/api/v2/webview/kyc/banks`;
  const response = await axios.get(url);
  result = response.data;
  return result;
}

export default {
  getBankList,
};
