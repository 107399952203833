import * as yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

class CustomDate extends yup.date {
  format(format: string) {
    return this.transform((value, input) => {
      const invalidDate = new Date('');
      const parsed = dayjs(input, format, true);
      return parsed.isValid() ? parsed.toDate() : invalidDate;
    });
  }
}

export const customDate = () => new CustomDate();
