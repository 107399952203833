import React from 'react';
import { StyleSheet, Text, View, ViewProps } from 'react-native';

import { colors } from './colors';
import { Space } from './space';

interface Props extends ViewProps {
  label: string;
  required?: boolean;
}

export const Field: React.FC<Props> = (props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>
        {props.label} {props.required && <Text style={styles.required}>*</Text>}
      </Text>
      <Space height={8} />
      <View style={styles.row}>{props.children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: colors.pale_grey,
    marginVertical: 8,
    paddingBottom: 8,
  },
  label: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 16,
  },
  required: {
    color: colors.tomato,
  },
  row: {
    flexDirection: 'row',
  },
});
