import React, { useEffect, useRef, useState, useContext } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { colors } from '../../components/colors';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { Header, Page, Button } from '../../components';
import { RootStackParamList } from '../../navigators';
import { isDesktop } from '../../utils';
import SignaturePad from 'react-signature-canvas';
import { AppStore } from '../../stores';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Camera'>;
  route: RouteProp<RootStackParamList, 'Camera'>;
}

export const SignatureScreen: React.FC<Props> = (props) => {
  const appStore = useContext(AppStore.Context);
  if (Platform.OS !== 'web') {
    useEffect(() => {
      (async () => {})();
    }, []);
  }

  const sigCanvas = useRef<SignaturePad>();
  function cancelSig() {
    sigCanvas.current.clear();
  }
  function saveSig() {
    const signature = sigCanvas.current.getCanvas().toDataURL();
    if (signature) {
      const { screen, field } = props.route.params;
      appStore.update((draft) => {
        draft.signatureResult[field] = signature;
      });
      props.navigation.goBack();
    }
  }

  return (
    <Page>
      <View>
        <Header onBack={() => props.navigation.goBack()} />
        <Text style={styles.title}>TANDA TANGAN</Text>
        <View style={styles.pad}>
          <SignaturePad
            ref={sigCanvas}
            backgroundColor="#ffffff"
            canvasProps={{
              width: 500,
              height: 300,
              clearOnResize: true,
            }}
            options={{
              minWidth: 1,
              maxWidth: 5,
            }}
          />
        </View>
        <Text style={{ textAlign: 'center', marginTop: 15 }}>
          Pastikan tanda tangan kamu berada di kotak di atas
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 10,
          }}
        >
          <View style={{ width: '45%' }}>
            <Button label="HAPUS" secondary onPress={cancelSig} />
          </View>
          <View style={{ width: '45%' }}>
            <Button label="SIMPAN" onPress={saveSig} />
          </View>
        </View>
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily: 'SFProDisplay-Regular',
    textAlign: 'center',
  },
  pad: {
    flex: 1,
    marginTop: 5,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#c4cdd5',
    marginHorizontal: 5,
    borderRadius: 5,
  },
  overlay: {
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    left: 0,
    paddingBottom: 24,
    position: 'absolute',
    right: 0,
  },
  col: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});
