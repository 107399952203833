import React from 'react';
import { ActivityIndicator, StyleSheet, TouchableOpacity } from 'react-native';

import { colors } from '../../../components';
import CameraIcon from './camera-24px.svg';

interface Props {
  loading?: boolean;
  onPress: () => void;
}

export const CameraButton: React.FC<Props> = (props) => {
  if (props.loading) {
    return <ActivityIndicator color={colors.tomato} style={styles.spinner} />;
  }
  return (
    <TouchableOpacity style={styles.container} onPress={props.onPress}>
      <CameraIcon />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 24,
    justifyContent: 'center',
    paddingHorizontal: 8,
    width: 24,
  },
  spinner: {
    height: 24,
    width: 24,
  },
});
