import { createStore } from '../utils';

export const AppStore = createStore({
  personalInfo: {
    idNumber: '',
    dateOfBirth: '',
  },
  cameraResult: {
    idPhoto: '',
    npwpPhoto: '',
    selfiePhoto: '',
  },
  signatureResult: {
    signature: '',
  },
});
