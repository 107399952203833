import * as yup from 'yup';

import { customDate } from '../../utils';

export const schema = yup.object().shape({
  idNumber: yup
    .string()
    .required()
    .length(16)
    .matches(/^[0-9]*$/),
  dateOfBirth: customDate().format('DD-MM-YYYY').required(),
});
