import { useFonts } from 'expo-font';

export function useCustomFonts() {
  const [fontsLoaded] = useFonts({
    'SFProDisplay-Bold': require('../../assets/fonts/SFProDisplay-Bold.otf'),
    'SFProDisplay-Heavy': require('../../assets/fonts/SFProDisplay-Heavy.otf'),
    'SFProDisplay-Medium': require('../../assets/fonts/SFProDisplay-Medium.otf'),
    'SFProDisplay-Regular': require('../../assets/fonts/SFProDisplay-Regular.otf'),
    'SFProDisplay-Semibold': require('../../assets/fonts/SFProDisplay-Semibold.otf'),
  });

  return fontsLoaded;
}
