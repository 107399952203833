import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

import { colors } from '../../components';

interface Props {
  value: string;
}

export const OtpInput: React.FC<Props> = (props) => {
  function getSlotStyle(i: number) {
    const slotStyle: StyleProp<ViewStyle> = [styles.slot];
    if (i === props.value.length) {
      slotStyle.push({ borderColor: colors.tomato });
    }
    return slotStyle;
  }

  return (
    <View style={styles.container}>
      {[0, 1, 2, 3].map((i) => (
        <View style={getSlotStyle(i)}>
          <Text style={styles.code}>{props.value[i]}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  slot: {
    alignItems: 'center',
    borderColor: colors.ink,
    borderBottomWidth: 4,
    justifyContent: 'center',
    marginRight: 8,
    width: 32,
  },
  code: {
    color: colors.ink,
    fontFamily: 'SFProDisplay-Bold',
    fontSize: 32,
    height: 48,
  },
});
