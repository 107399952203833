import React from 'react';
import { StyleSheet, View } from 'react-native';

import { getPageWidth } from '../../../utils';

interface Props {}

export const IdCardMask: React.FC<Props> = (props) => {
  return (
    <View style={styles.container}>
      <View style={styles.marker} />
    </View>
  );
};

const markerWidth = getPageWidth() - 32;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  marker: {
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 8,
    borderWidth: 4,
    height: 0.63 * markerWidth,
    width: markerWidth,
  },
});
