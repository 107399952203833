import axios from 'axios';
import { CameraCapturedPicture } from 'expo-camera';

interface Data {
  base64: string;
  keys: string;
  nik: string;
}

async function uploadPhoto(data: Data): Promise<string> {
  let result = '';
  if (data.base64) {
    const url = 'https://api.siminvest.co.id/api/v2/webview/kyc/upload';
    const formData = new FormData();
    const base64response = await fetch(data.base64);
    const extention = data.base64.substring(
      data.base64.lastIndexOf('data:image/') + 11,
      data.base64.lastIndexOf(';')
    );
    const blob = await base64response.blob();
    formData.append('file', blob, `${data.nik}-${data.keys}.${extention}`);
    formData.append('nik', data.nik);
    formData.append('keys', data.keys);
    const response = await axios.post<string>(url, formData, {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQbmxaMGJHdm1jWmZpWlRzNmlkd1BJZ01BUEpGWnZKMiIsInN1YiI6IlNpbWFzSW52ZXN0In0.LLN3Yg1bnFSt0DlH7wCC0q4rfMKPezxDyCRc83EATTM',
        'Content-Type': 'multipart/form-data',
      },
    });
    result = response.data;
  }
  return result;
}

export default {
  uploadPhoto,
};
