import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import CameraIcon from './camera-32px.svg';

interface Props {
  onPress: () => void;
}

export const CaptureButton: React.FC<Props> = (props) => (
  <TouchableOpacity onPress={props.onPress}>
    <View style={styles.container}>
      <View style={styles.inner}>
        <CameraIcon />
      </View>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderColor: '#FFF',
    borderRadius: 48,
    borderWidth: 4,
    height: 96,
    justifyContent: 'center',
    width: 96,
  },
  inner: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: 40,
    height: 80,
    justifyContent: 'center',
    width: 80,
  },
});
