import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import {
  colors,
  DigitPad,
  Header,
  Page,
  Space,
  Subtitle,
  Title,
} from '../../components';
import { RootStackParamList } from '../../navigators';

import { OtpInput } from './otp-input';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'Otp'>;
  route: RouteProp<RootStackParamList, 'Otp'>;
}

export const OtpScreen: React.FC<Props> = (props) => {
  const [codes, setCodes] = useState('');

  function addCode(code: string) {
    if (code !== '.') {
      let newCodes;
      if (code === '<-') {
        newCodes = codes.slice(0, -1);
      } else {
        newCodes = codes + code;
      }
      setCodes(newCodes);
    }
  }

  return (
    <Page>
      <Header onBack={() => props.navigation.goBack()} />
      <View style={styles.container}>
        <Space />
        <Title>Agar aman, harap verifikasi nomor kamu</Title>
        <Space height={8} />
        <Subtitle>
          Kami mengirimkan kode melalui SMS ke nomor +62811234567
        </Subtitle>
        <Space height={50} />
        <OtpInput value={codes} />
        <Space />
        <Text style={styles.text}>Tidak menerima SMS? Kirim Ulang (29)</Text>
      </View>
      <DigitPad onSelectValue={(value) => addCode(value)} />
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
  },
  text: {
    color: colors.battleship_grey,
    fontFamily: 'SFProDisplay-Regular',
    fontSize: 14,
  },
});
