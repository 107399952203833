import axios from 'axios';

interface Data {
  id_number: string;
  date_of_birth: string;
}

export async function dukcapil(data: Data) {
  const url = 'https://api.siminvest.co.id/api/v2/webview/kyc/dukcapil';
  const response = await axios.post(url, data, {
    headers: {
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQbmxaMGJHdm1jWmZpWlRzNmlkd1BJZ01BUEpGWnZKMiIsInN1YiI6IlNpbWFzSW52ZXN0In0.LLN3Yg1bnFSt0DlH7wCC0q4rfMKPezxDyCRc83EATTM',
    },
  });
  return response.data;
}

export default {
  dukcapil,
};
