import React, { useContext, useEffect, useState } from 'react';
import {
  Image,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
} from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import dayjs from 'dayjs';

import {
  colors,
  ErrorMessage,
  Field,
  FooterButton,
  Header,
  Page,
  Space,
  Subtitle,
  Title,
} from '../../components';
import api from '../../api';
import { RootStackParamList } from '../../navigators';
import { AppStore } from '../../stores';
import { CameraButton } from './camera-button';
import { schema } from './schema';

interface Props {
  navigation: StackNavigationProp<RootStackParamList, 'IdVerification'>;
  route: RouteProp<RootStackParamList, 'IdVerification'>;
}

interface Data {
  idNumber: string;
  dateOfBirth: string;
}

export const IdVerificationScreen: React.FC<Props> = (props) => {
  const appStore = useContext(AppStore.Context);
  const [loading, setLoading] = useState(false);

  const { control, errors, handleSubmit, setValue } = useForm<Data>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function run() {
      const { idPhoto } = appStore.state.cameraResult;
      if (idPhoto !== '') {
        setLoading(true);
        try {
          const result = await api.ocr.postOcr(idPhoto);
          setValue('idNumber', result.ktp, {
            shouldDirty: true,
            shouldValidate: true,
          });
        } finally {
          setLoading(false);
        }
      }
    }
    run();
  }, [appStore.state.cameraResult.idPhoto]);

  const submit = handleSubmit(async (data) => {
    try {
      await api.users.dukcapil({
        id_number: data.idNumber,
        date_of_birth: dayjs(data.dateOfBirth).format('DD/MM/YYYY'),
      });
      appStore.update((draft) => {
        draft.personalInfo.idNumber = data.idNumber;
        draft.personalInfo.dateOfBirth = dayjs(data.dateOfBirth).format(
          'DD-MM-YYYY'
        );
      });
      props.navigation.navigate('Registration');
    } catch (error) {
      window.open(
        'https://financial.sinarmassekuritas.co.id/simas.net/reginternet/RegInternet.asp?digisign=DGS',
        '_self'
      );
    }
  });

  const textInputStyle: StyleProp<TextStyle> = [styles.textInput];
  if (Platform.OS === 'web') {
    textInputStyle.push({
      outline: 'none',
      outlineStyle: 'none',
      outlineWidth: 0,
      outlineColor: 'transparent',
    } as any);
  }

  return (
    <Page>
      <Header onBack={() => props.navigation.goBack()} />
      <ScrollView contentContainerStyle={styles.container}>
        <Title small>Verifikasi KTP</Title>
        <Space height={8} />
        <Subtitle>Pastikan Anda memasukan data KTP dengan benar.</Subtitle>
        <Space height={24} />
        {appStore.state.cameraResult.idPhoto !== '' && (
          <Image
            resizeMode="contain"
            source={{ uri: appStore.state.cameraResult.idPhoto }}
            style={styles.image}
          />
        )}
        <Space height={16} />
        <Field label="Nomor KTP" required>
          <View style={styles.input}>
            <Controller
              control={control}
              name="idNumber"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInput
                  editable={!loading}
                  placeholder="1234567890123456"
                  placeholderTextColor={colors.pale_grey}
                  keyboardType="phone-pad"
                  style={textInputStyle}
                  maxLength={16}
                  value={value}
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
          </View>
          <CameraButton
            loading={loading}
            onPress={() => {
              props.navigation.navigate('Camera', {
                mask: 'id-card',
                screen: 'IdVerification',
                field: 'idPhoto',
              });
            }}
          />
        </Field>
        <ErrorMessage
          error={errors.idNumber}
          message="Nomor ktp harus terdiri dari 16 angka"
        />
        <Field label="Tanggal Lahir" required>
          <View style={styles.input}>
            <Controller
              control={control}
              name="dateOfBirth"
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextInputMask
                  keyboardType="phone-pad"
                  placeholder="DD-MM-YYYY"
                  placeholderTextColor={colors.pale_grey}
                  type="custom"
                  options={{ mask: '99-99-9999' }}
                  style={textInputStyle}
                  value={value}
                  onChangeText={(text) => onChange(text)}
                />
              )}
            />
          </View>
        </Field>
        <ErrorMessage
          error={errors.dateOfBirth}
          message="Tanggal tidak valid"
        />
      </ScrollView>
      <FooterButton onPress={submit} />
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  image: {
    height: 192,
    width: undefined,
  },
  input: {
    flex: 1,
  },
  textInput: {
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: 16,
  },
});
