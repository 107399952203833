import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';

import { colors } from './colors';

interface Props {
  label: string;
  secondary?: boolean;
  onPress: () => void;
}

export const Button: React.FC<Props> = (props) => {
  const containerStyle: StyleProp<TextStyle> = [styles.container];
  const labelStyle: StyleProp<TextStyle> = [styles.label];
  if (props.secondary) {
    containerStyle.push({
      backgroundColor: colors.white,
    });
    containerStyle.push({
      borderColor: colors.ink_lightest,
      borderWidth: 1,
    });
    labelStyle.push({
      color: colors.ink,
    });
  }
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={containerStyle}>
        <Text style={labelStyle}>{props.label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: colors.tomato,
    borderRadius: 8,
    flexDirection: 'row',
    height: 56,
    justifyContent: 'center',
  },
  label: {
    color: colors.white,
    fontFamily: 'SFProDisplay-Semibold',
    fontSize: 14,
    letterSpacing: 1.58,
  },
});
